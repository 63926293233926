<template>
  <span>
    <span class="d-flex justify-content-between">
      <span class="d-flex flex-row" v-if="current_position">
        <b-form-group
          label="Latitude"
          label-for="lat"
        >
          <b-form-input
            id="lat"
            size="sm"
            v-model="current_position.lat"
            @change="updateMarkerPosition()"
          />
        </b-form-group>
        <b-form-group
          label="Longitude"
          label-for="lng"
        >
          <b-form-input
            id="lng"
            size="sm"
            v-model="current_position.lng"
            @change="updateMarkerPosition()"
          />
        </b-form-group>
      </span>
      <span>
        <b-button
          variant="gradient-primary"
          class="btn-icon"
          size="sm"
          @click="get_location()"
        >
          <i class="las la-redo-alt"></i>
          Lancer la récupération de la localisation
          <b-spinner
            v-if="is_loading_getting_location"
            small
          />
        </b-button>
      </span>
    </span>
    
    <div
      :id="mapId" ref="mapContainer"
      style="width: 100%; height: 400px"
    />
  </span>
</template>

<script>
import {
  BRow, BCol, BPagination, VBTooltip, BFormSelect, BButton, BSpinner,
  BFormInput, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    VBTooltip,
    BFormSelect,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
  },
  props: {
    mapId: {
      type: String,
      required: true,
    },
    // tableData: {
    //   type: Array,
    //   default: () => [],
    // },
    // eslint-disable-next-line vue/require-default-prop
    isDraggable: {
      default: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    positions: {
      type: Array,
      default: () => [],

    },
  },
  data() {
    return {
      current_position: null,
      current_accuracy: null,
      map: null,
      is_loading_getting_location: false,
      curr_marker : null,
      // current_position : {lng: null, lat: null}
      // per_pageLocal: this.$props.per_page,
      // per_pageValues: [2, 10, 20, 100, 200],
    }
  },
  computed: {
    tableDataLength() {
      return (this.tableData && this.tableData.length) || 0
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {},
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // Initialise la carte et définit sa vue sur les coordonnées et le niveau de zoom
      this.map = L.map(this.$refs.mapContainer).setView([9.3220475, 2.313137999999981], 9) // Initialisation de la carte
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(this.map)
      // Ajuster le zoom de la carte
      this.map.locate({ setView: true, maxZoom: 16, timeout:30000})
      // ajouter les marqueurs des différentes positions du props
      if (this.positions.length !== 0) {
        // console.log('en mode affichage::: 🟢',);
        this.positions.forEach(position => {
          L.marker([position.lat, position.lng], { draggable: this.isDraggable }).addTo(this.map)
        })
        return
      }

      this.get_location()
      
      // Ajoute un marqueur sur la carte aux coordonnées spécifiées
      // const marker = L.marker([6.370252526698369, 2.4114899447997344]).addTo(
      //   map
      // );

      // Fonction pour gérer le clic sur la carte et récupérer les coordonnées
      // map.on("click", (e) => {
      //   const coord = e.latlng;
      //   const { lat, lng } = coord;
      //   this.$emit("on_position_fixed", { lng, lat });
      //   // Optionnel : Ajoute un marqueur à l'endroit cliqué
      //   L.marker([lat, lng]).addTo(map);
      // });
    },
    get_location(){
      // console.log('get_location::: 🟢🟢',);
      this.is_loading_getting_location = true

      this.map.on('locationfound', this.onLocationFound)
      this.map.on('locationerror', this.onLocationError)
    },
    onLocationFound(e) {
      this.is_loading_getting_location = false
      const radius = e.accuracy / 2
      this.curr_marker = L.marker(e.latlng, { draggable: this.isDraggable })
        .addTo(this.map)
        .bindPopup(`Vous êtes à environ ${radius} mètres de ce point`)
        .openPopup()
      L.circle(e.latlng, radius).addTo(this.map)

      this.map.setView(e.latlng, 13) // Ajustez 16 au niveau de zoom souhaité

      const position = this.curr_marker.getLatLng()
      this.current_position = { lng: position.lng, lat: position.lat }
      this.$emit('on_position_fixed', {
        lng: position.lng,
        lat: position.lat,
      })

      this.curr_marker.on('dragend', event => {
        const marker = event.target
        const position = marker.getLatLng()
        this.$emit('on_position_fixed', {
          lng: position.lng,
          lat: position.lat,
        })
        this.current_position = { lng: position.lng, lat: position.lat}
        // Ici, vous pouvez ajouter le code pour traiter la nouvelle position
      })
    },
    onLocationError(e) {
      this.is_loading_getting_location = false
      console.log('e::: 🔴', e);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Erreur lors de la récupération de la position.",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      
      // alert(e.message)
    },
    updateMarkerPosition() {
      // Mettre à jour la position du marqueur en fonction des nouvelles valeurs de latitude et longitude
      if (this.curr_marker) {
        this.curr_marker.setLatLng([parseFloat(this.current_position.lat), parseFloat(this.current_position.lng)]);
      }
    },
    // Fonction pour nettoyer les marqueurs et ajouter un nouveau marqueur avec des coordonnées spécifiques
    cleanAndAddMarker(radius) {
      // Nettoyer tous les marqueurs existants sur la carte
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.map.removeLayer(layer);
        }
      });

      const marker = L.marker(e.latlng, { draggable: this.isDraggable })
        .addTo(this.map)
        .bindPopup(`Vous êtes à environ ${radius} mètres de ce point`)
        .openPopup()
      L.circle(e.latlng, radius).addTo(this.map)


      this.map.setView(e.latlng, 13) // Ajustez 16 au niveau de zoom souhaité

      const position = marker.getLatLng()
      this.$emit('on_position_fixed', {
        lng: position.lng,
        lat: position.lat,
      })
      this.current_position = { lng: position.lng, lat: position.lat }

      marker.on('dragend', event => {
        const marker = event.target
        const position = marker.getLatLng()
        this.$emit('on_position_fixed', {
          lng: position.lng,
          lat: position.lat,
        })
        this.current_position = { lng: position.lng, lat: position.lat}
        // Ici, vous pouvez ajouter le code pour traiter la nouvelle position
      })
    },
    
  },

}
</script>

<style scoped lang="scss">
@import '@core/scss/base/bootstrap-extended/include';

ul.pagination.b-pagination {
  margin-bottom: 0;
}
.pagination-ctn {
  @media (max-width: 768px) {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.pagination {
  @media (max-width: 768px) {
    justify-content: center !important;
    width: 100%;
  }
}
</style>
